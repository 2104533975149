import styled from 'styled-components';

import {
  Header as BaseHeader,
} from '../../components/Header/styles';

const CartSection = styled.section`
  background: #2a95d5 url(../Assets/bg-vend-market.jpg);
  position: relative;
  width: 100%;
  overflow-y: auto;
  justify-content:center;
  text-align:center;
  height:100vh;
`;

const StylesModal = {
  modalOverlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100vw',
    height: '100vh',
    background: 'rgba(0, 0, 0, 0.6)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1000,
  },
  modalContent: {
    background: '#fff',
    padding: '1.5rem',
    borderRadius: '0.5rem',
    textAlign: 'center',
    boxShadow: '0 0.4rem 0.8rem rgba(0, 0, 0, 0.2)',
    width: '24rem', 
    height: '16rem',
    maxWidth: '90%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  modalHeading: {
    fontSize: '1.25rem',
    marginBottom: '0.5rem',
    color: '#333',
  },
  modalImage: {
    maxWidth: '60%',
    maxHeight: '6rem', 
    margin: '0.5rem 0',
    borderRadius: '0.25rem',
  },
  modalButton: {
    display: 'inline-block',
    padding: '0.5rem 1rem',
    fontSize: '0.875rem',
    fontWeight: 'bold',
    color: '#fff',
    background: '#005bb8',
    border: 'none',
    borderRadius: '0.25rem',
    cursor: 'pointer',
    textAlign: 'center',
  }
};


const Content = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-flow: column;
  flex-grow: 1;
  padding:2em;
`;

const LogoVend = styled.img`
  height: 6rem;
  width:auto;
`;

const Header = styled(BaseHeader)`

  align-items:center;
  justify-content:center;
  display:flex;
  padding-top:8em;

  @media (max-width: 1024px) {
    padding:5em 0 0 0;
    margin: 2em 0 0 0;
  }

  @media (max-width: 320px) {
    margin-top: 10px;
  }
`;


const SpinButton = styled.button`
  margin-top: 3rem;
  background-color:#045880;
  color: #fff;
  padding: 1.25rem 2.5rem;
  font-size: 1.25rem; 
  border: none;
  border-radius: 0.7rem; 
  opacity: ${({ disabled }) => (disabled ? 0.6 : 1)};
`;

const RouletteWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 1rem; 
`;

export {
  Header,
  CartSection,
  LogoVend,
  Content,
  SpinButton,
  StylesModal,
  RouletteWrapper
};
