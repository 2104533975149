import React, { 
  useState, 
  useEffect, 
  useContext, 
} from 'react';
import { axiosGet } from '../../services/api';
import { useHistory } from 'react-router-dom';

import { LowerAdSection } from '../../styles/globalStyle';
import {
  CartSection,
  Header,
  LogoVend,
  Content,
  SpinButton,
  StylesModal,
  RouletteWrapper,
} from './styles';

import { Wheel } from 'react-custom-roulette';

import UserContext from '../../state/UserContext';
import TimerContext from '../../state/TimerContext';
import { CartContext } from '../../state/CartContext';

import background from './Assets/bg-vend-market.jpg';
import logotipovend from './Assets/logo-vend-market.svg';
import people04 from './Assets/people04.png';

function Roleta() {
  const [mustSpin, setMustSpin] = useState(false);
  const [prizeNumber, setPrizeNumber] = useState(0);
  const [selectedPrize, setSelectedPrize] = useState(null);
  const [items, setItems] = useState([]); 
  const [allItems, setAllItems] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [userInfo] = useContext(UserContext);
  const { timer } = useContext(TimerContext);
  const { cartDispatch } = useContext(CartContext); 

  const history = useHistory();

  useEffect(() => {
    if (!userInfo) return;
    if (!userInfo.machineId) return;

    const fetchProducts = async () => {
      const { data: machineData } = await axiosGet(`/machines/${userInfo.machineId}?populate=true`);

      if (!machineData) return;

      const { slots } = machineData?.data;

      const validSlots = machineData?.data.type === 'Totemach' 
        ? slots
        : slots.filter((slot) => slot.quantity > 0);

      const itemsInfo = validSlots.filter(slot => slot.itemId !== null && slot.itemId).map(({ itemId }) => itemId);
      const productsInfo = itemsInfo.map(({ productId }) => productId);

      const mergedItemsAndProducts = itemsInfo.map((itemInfo, index) => ({
        ...productsInfo[index],
        ...itemInfo,
      }));

      const duplicatedItems = createAlternatedItems(mergedItemsAndProducts);

      const formattedData = duplicatedItems.map((item, index) => ({
        option: item.name,
        id: item._id,
        style: { backgroundColor: index % 2 === 0 ? '#006494' : '#33c4e3' },
      }));

      setItems(formattedData);
      setAllItems(mergedItemsAndProducts);
    };

    const redirectIfOffLine = () => {
      if (userInfo.isOnline === false) {
        console.log(`Offline, redirecionando para os ads`);
        timer.showSleep = true;
      }
    };

    fetchProducts();
    redirectIfOffLine();
  }, [userInfo]);

  const createAlternatedItems = (items) => {
    const itemCount = items.length;

    if (itemCount >= 2 && itemCount <= 9) {
      const duplicatedCount = itemCount * 2; 
      const alternatedItems = [];

      for (let i = 0; i < duplicatedCount; i++) {
        alternatedItems.push(items[i % itemCount]);
      }

      return alternatedItems;
    }

    return items;
  };

  const handleSpinClick = () => {
    if (!mustSpin && items.length > 0) {
      const newPrizeNumber = Math.floor(Math.random() * items.length);

      setPrizeNumber(newPrizeNumber);
      setMustSpin(true);
    }
  };

  const handleStopSpinning = () => {
    setMustSpin(false);
    const selected = items[prizeNumber];
    setSelectedPrize(selected);

    const fullDetail = allItems.find((item) => item._id === selected.id);

    cartDispatch({
      type: 'ADD',
      itemId: fullDetail._id,
      price: fullDetail.price,
    });

    setIsModalOpen(true);
  };

  const handleCheckout = () => {
    setIsModalOpen(false);

    history.push('/checkout', {
      celular: userInfo?.customer?.phone || null, 
    });
  };

  return (
    <>
      <LowerAdSection>
        <CartSection
          className="bg-inicial"
          style={{
            backgroundImage: `url(${background})`,
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
          }}
        >
          <Header
            
          >
            <LogoVend src={logotipovend} />
          </Header>
          <Content>
            {items.length > 0 ? (
              <>
                <RouletteWrapper>
                  <Wheel
                    mustStartSpinning={mustSpin}
                    prizeNumber={prizeNumber}
                    data={items} // Dados dinâmicos
                    spinDuration={0.6} // Tempo do giro
                    onStopSpinning={handleStopSpinning}
                    textDistance={65} // Ajusta a distância do texto em relação ao centro (valor ideal: entre 50-80)
                    fontSize={16} // Ajusta o tamanho da fonte (valor ideal: entre 12-18)
                    innerRadius={10} // (Opcional) Ajusta o centro da roleta para melhor espaço
                    innerBorderColor="#fff" // Define a cor do centro da roleta
                    radiusLineColor="#000" // Define a cor das linhas divisórias
                  />
                  <SpinButton onClick={handleSpinClick} disabled={mustSpin}>GIRAR</SpinButton>
                </RouletteWrapper>
              </>
            ) : (
              <div style={{ textAlign: 'center', padding: '20px' }}>
                <div className="spinner" />
                <p>Carregando a roleta...</p>
              </div>
            )}
          </Content>
        </CartSection>
      </LowerAdSection>
  
      {isModalOpen && (
        <div style={StylesModal.modalOverlay}>
          <div style={StylesModal.modalContent}>
            <h2 style={StylesModal.modalHeading}>
              Parabéns! Você ganhou <strong>{selectedPrize?.option}</strong>
            </h2>
            <img
              src={allItems.find(item => item._id === selectedPrize?.id)?.image}
              alt="Prêmio"
              style={StylesModal.modalImage}
            />
            <button style={StylesModal.modalButton} onClick={handleCheckout}>
              RETIRE SEU PRÊMIO
            </button>
          </div>
        </div>
      )}
    </>
  );
}

export default Roleta;
