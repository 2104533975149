import React, {
  useState,
  useEffect,
  useContext,
} from 'react';

import { Redirect, useLocation, useHistory } from 'react-router';
import { useCookies } from 'react-cookie';
import { toast } from 'react-toastify';

import { Button, Input, Switch } from 'antd';
import { LowerAdSection } from '../../styles/globalStyle';
import {
  CartSection,
  Content,
  Header,
  LogoVend,
} from './styles';

import api, { axiosGet, axiosPost } from '../../services/api';
import logotipovend from './Assets/logo-vend-market.svg';
import people03 from './Assets/people03.png';
import background from './Assets/bg-vend-market.jpg';
import UserContext from '../../state/UserContext';

function AtivacaoCadastro() {
  const [redirect, setRedirect] = useState(null);
  const [phone, setPhone] = useState('');
  const [phoneCode, setPhoneCode] = useState('');
  const [phoneLastNumbers, setPhoneLastNumbers] = useState('3746');
  const [validationCode, setValidationCode] = useState('');
  const [size, setSize] = useState('large');
  const { state } = useLocation();

  const [userInfo, setUserInfo] = useContext(UserContext);
  const [, setCookie, removeCookies] = useCookies(['token', 'user_id', 'qrcode', 'touchless']);

  const location = useLocation();
  const history = useHistory();

  const backNavigation = () => setRedirect('../');
  const goTo = (page) => {
    setRedirect({ pathname: `/${page}` });
  };

  const setValidationCodeAndNext = (value, nextInputName) => {
    setValidationCode(validationCode + value);
    if (nextInputName !== '') { document.querySelector(`input[name=${nextInputName}]`).focus(); }
  };

  const handleLogin = async ({
    customer, user, token, qrcode,
  }, touchless) => {
    removeCookies('token', { path: '/' });
    removeCookies('user_id', { path: '/' });
    removeCookies('customer_id', { path: '/' });
    localStorage.removeItem('ads');
    localStorage.removeItem('userData');
    localStorage.removeItem('qrcode');

    const finish = () => {
      removeCookies('token', { path: '/' });
      removeCookies('user_id', { path: '/' });
      removeCookies('customer_id', { path: '/' });
    };

    const expires = new Date();
    expires.setFullYear(expires.getFullYear() + 70);

    setCookie('token', token, { path: '/', expires });
    setCookie('user_id', user._id, { path: '/', expires });
    setCookie('customer_id', customer._id, { path: '/', expires });
    setCookie('touchless', touchless, { path: '/', expires });
    if (!touchless) localStorage.setItem('qrcode', JSON.stringify(qrcode));

    if (user.machineId == null) {
      toast.error('Essa conta não está vinculada à uma máquina');

      finish();
      return;
    }

    setUserInfo({
      ...user,
      customer,
      isAdmin: false,
      qrcode,
    });

    if (userInfo?.machine?.sampling) {
      history.push('/roleta');
    } else {
      history.push('/');
    }
  };

  const handleSubmit = async () => {
    if (validationCode != phoneCode) {
      toast.error('O código informado está incorreto. Tente novamente.');
      setValidationCode('');
    } else {
      toast.success('Validação realizada com sucesso. Seja bem vindo!');

      const { machineId } = userInfo;
      const res = await api.post('/customers/auth', { email: state.email, machineId });
      
      const customerId = res.data.data.customer._id; 
      const phoneValidated = res.data.data.customer.phone_validated; 

      if (!phoneValidated) { 
        await api.patch(`/customers/${customerId}`, { phone_validated: new Date() });
      }
    
      await handleLogin(res.data.data, true);
    }
  };

  const reSendSms = async () => {
    try {
      const res = await api.post('/customers/resend-sms', { name: state?.name, phone: state?.phone });
      console.log(res);
      setPhoneCode(res?.data?.data?.validation_code);
      toast.success('Um novo código de acesso foi enviado por WhatsApp para o celular informado.', { autoClose: 10000 });
    } catch (error) {
      console.error(error);
      toast.error('Ocorreu um erro. Tente novamente em breve.');
    }
  };

  useEffect(() => {
    setPhone(state?.phone);
    setPhoneCode(state?.phone_code_validation);
    setPhoneLastNumbers(state?.phone.substr(-4));
  }, []);

  if (redirect) return <Redirect to={redirect} />;

  return (
    <>
      <LowerAdSection>
        <CartSection className="bg-inicial" style={{ backgroundImage: `url(${background})`, backgroundSize: 'cover', backgroundRepeat: 'no-repeat' }}>
          <Header style={{
            backgroundImage: `url(${people03})`,
            backgroundSize: '30%',
            backgroundPosition: 'top center',
            backgroundRepeat: 'no-repeat',
          }}
          >
            <LogoVend src={logotipovend} />
          </Header>
          <Content>
            <h2 style={{ fontWeight: 'bolder' }}>Ativação de Conta</h2>
            <h3>
              Enviamos um código de segurança por WhatsApp para o celular ***-
              {phoneLastNumbers}
            </h3>
            <h3 className='frase'>Por favor, insira o código abaixo:</h3>

            <div className="codigo">
              <Input type='tel' pattern="[0-9]*" size="large" placeholder="" style={{ textAlign: 'center' }} maxLength={1} name="validation-code-1" onChange={(e) => setValidationCodeAndNext(e.target.value, 'validation-code-2')} />
              <Input type='tel' pattern="[0-9]*" size="large" placeholder="" style={{ textAlign: 'center' }} maxLength={1} name="validation-code-2" onChange={(e) => setValidationCodeAndNext(e.target.value, 'validation-code-3')} />
              <Input type='tel' pattern="[0-9]*" size="large" placeholder="" style={{ textAlign: 'center' }} maxLength={1} name="validation-code-3" onChange={(e) => setValidationCodeAndNext(e.target.value, 'validation-code-4')} />
              <p>-</p>
              <Input type='tel' pattern="[0-9]*" size="large" placeholder="" style={{ textAlign: 'center' }} maxLength={1} name="validation-code-4" onChange={(e) => setValidationCodeAndNext(e.target.value, 'validation-code-5')} />
              <Input type='tel' pattern="[0-9]*" size="large" placeholder="" style={{ textAlign: 'center' }} maxLength={1} name="validation-code-5" onChange={(e) => setValidationCodeAndNext(e.target.value, 'validation-code-6')} />
              <Input type='tel' pattern="[0-9]*" size="large" placeholder="" style={{ textAlign: 'center' }} maxLength={1} name="validation-code-6" onChange={(e) => setValidationCodeAndNext(e.target.value, '')} />
            </div>
            <Button type="primary" size={size} block className="azul" onClick={() => handleSubmit()}>
              Confirmar
            </Button>
            <Button size={size} block onClick={() => reSendSms()}>
              Reenviar código
            </Button>
          </Content>
        </CartSection>
      </LowerAdSection>
    </>
  );
}

export default AtivacaoCadastro;
