import React, { useReducer } from 'react';
import {
  BrowserRouter as Router,
  Route,
  Switch,
} from 'react-router-dom';
import { CookiesProvider } from 'react-cookie';
import LoggedRoutes from './LoggedRoutes';
import { UserProvider } from '../state/UserContext';
import { CartContext, cartReducer } from '../state/CartContext';
import { TimerProvider } from '../state/TimerContext';
import { AdProvider } from '../state/AdContext';
import { CouponProvider } from '../state/CouponContext';
import { NotFound, ToggleHideCursor, } from '../pages';
import { NavBar } from '../components';
import HideCursorChecker from './HideCursorChecker';
import Expired from '../pages/Expired';
import AuthCustomer from '../pages/AuthCustomer';
import RegisterCustomer from '../pages/RegisterCustomer';
import MobileValidate from '../pages/MobileValidate';
import CustomerMyData from '../pages/CustomerMyData';
import CustomerMenu from '../pages/CustomerMenu';
import CustomerCards from '../pages/CustomerCards';
import BemVindo from '../pages/BemVindo';
import Cadastro from '../pages/Cadastro';
import AtivacaoCadastro from '../pages/AtivacaoCadastro';
import ValidacaoCadastro from '../pages/ValidacaoCadastro';
import Privacidade from '../pages/Privacidade';
import Termos from '../pages/Termos';

function Routes() {
  const [cartState, cartDispatch] = useReducer(cartReducer, {});

  return (
    <CookiesProvider>
      <HideCursorChecker />
      <UserProvider>
        <CouponProvider>
          <CartContext.Provider value={{ cartState, cartDispatch }}>
            <AdProvider>
              <Router>
                <TimerProvider>
                  <Switch>
                    <Route exact path="/toggle-hide-cursor">
                      <ToggleHideCursor />
                    </Route>
                    <Route exact path="/expired">
                      <Expired />
                    </Route>
                    <Route exact path="/auth_customer">
                      <AuthCustomer />
                    </Route>
                    <Route exact path="/register_customer">
                      <RegisterCustomer />
                    </Route>
                    <Route exact path="/mobile_validate">
                      <MobileValidate />
                    </Route>
                    <Route exact path="/customer_mydata">
                      <CustomerMyData />
                    </Route>
                    <Route exact path="/customer_menu">
                      <CustomerMenu />
                    </Route>
                    <Route exact path="/customer_cards">
                      <CustomerCards />
                    </Route>
                    <Route exact path="/bem-vindo">
                      <BemVindo />
                    </Route>
                    <Route exact path="/cadastro">
                      <Cadastro />
                    </Route>
                    <Route exact path="/ativacao-cadastro">
                      <AtivacaoCadastro />
                    </Route>
                    <Route exact path="/validacao-cadastro">
                      <ValidacaoCadastro />
                    </Route>
                    <Route exact path="/privacidade">
                      <Privacidade />
                    </Route>
                    <Route exact path="/termos">
                      <Termos />
                    </Route>
                    <Route path="/(|carrinho|checkout|payment|success|roleta|logout)">
                      <LoggedRoutes />
                    </Route>
                    <Route>
                      <NavBar />
                      <NotFound />
                    </Route>
                  </Switch>
                </TimerProvider>
              </Router>
            </AdProvider>
          </CartContext.Provider>
        </CouponProvider>
      </UserProvider>
    </CookiesProvider>
  );
}

export default Routes;
